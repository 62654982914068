import { filter } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-hot-toast';
// @mui
import {
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { ModalSuppression } from '../components/modal';
import { defaultRowPerPage, REQ_STATUT, valeurPagination } from '../config/constants';
import withAuth from '../hoc/withAuth';
import { AffectationForm, AffectationsToolbar } from '../sections/affectations';
import { useListeAffectationsQuery, useSupprimerAffetationMutation } from '../services';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'libelle_categorie', label: 'Catégorie', alignRight: false },
  { id: 'libelle_type', label: 'Type utilisateurs', alignRight: false },
  //   { id: 'etat_categorie', label: 'Etat', alignRight: false },
  { id: 'created_at', label: 'Affecté le/par', alignRight: true },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_aff) =>
        _aff.libelle_categorie.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _aff.libelle_type.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const AffectationsPage = () => {
  const {
    data: listeAffectations,
    isFetching: chargementAffectations,
    refetch: chargerAffectations,
  } = useListeAffectationsQuery();
  const [supprimerAffectation] = useSupprimerAffetationMutation();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);

  const [showForm, setShowForm] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [currentItem, setCurrentItem] = useState({});

  const [openModalSuppression, setOpenModalSuppression] = useState(false);
  const [openModalEtat, setOpenModalEtat] = useState(false);
  const [chargement, setChargement] = useState(false);

  const handleOpenModalSuppression = () => {
    if (openModalSuppression === true) {
      setCurrentItem({});
    }
    setOpenModalSuppression((state) => !state);
    handleCloseMenu();
  };

  const handleOpenModalEtat = () => {
    if (openModalEtat === true) {
      setCurrentItem({});
    }
    setOpenModalEtat((state) => !state);
    handleCloseMenu();
  };

  const handleSupprimerAffectation = () => {
    setChargement(true);
    const data = { affectation_id: currentItem && currentItem.id, etat_affectation: 0 };

    supprimerAffectation(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            toast.success(res.message);
            setChargement(false);
            handleOpenModalSuppression();
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  //   const handleModifierEtatCategorie = () => {
  //     setChargement(true);
  //     const data = {
  //       categorie_id: currentItem && currentItem.id,
  //       etat_categorie: currentItem.etat_categorie === 1 ? 0 : 1,
  //     };

  //     modifierEtatCategorie(data)
  //       .unwrap()
  //       .then(
  //         (res) => {
  //           if (res.statut === REQ_STATUT.SUCCES) {
  //             chargerAffectations();
  //             toast.success(res.message);
  //             setChargement(false);
  //             handleOpenModalEtat();
  //           } else {
  //             setChargement(false);
  //             toast.error(res.message);
  //           }
  //         },
  //         (err) => {
  //           setChargement(false);
  //           toast.error(err.error);
  //         }
  //       );
  //   };

  const handleOpenMenu = (event, item) => {
    setOpen(event.currentTarget);
    setCurrentItem(item);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listeAffectations.map((n) => n.libelle_categorie);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleShowForm = (item, isEdit) => {
    // if (isEdit === true) {
    //   setCurrentItem(item);
    // }
    handleCloseMenu();
    setIsEditing(isEdit);
    setShowForm((state) => !state);
  };

  // const chargerAffectations = () => {
  //   getListeAffectations()
  //     .unwrap()
  //     .then(
  //       (res) => {
  //         if (res.statut === REQ_STATUT.SUCCES) {
  //           setListeAffectations(res.resultat);
  //         }
  //       },
  //       (err) => {}
  //     );
  // };
  useEffect(() => {
    chargerAffectations();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (listeAffectations?.length ?? 0)) : 0;
  // console.log(emptyRows);
  const filteredAffectations = applySortFilter(listeAffectations ?? [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredAffectations.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Affectations | Asaci </title>
      </Helmet>
      <Container>
        {showForm === false ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Affectations
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleShowForm({}, false)}
            >
              Nouvelle affectation
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {isEditing ? 'Modification affectation' : 'Nouvelle affectation'}
            </Typography>
            <Button
              variant="text"
              onClick={() => handleShowForm()}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              Revenir aux affectations
            </Button>
          </Stack>
        )}

        {showForm === false ? (
          <Card>
            <AffectationsToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
            {chargementAffectations && <LinearProgress />}
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listeAffectations?.length ?? 0}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredAffectations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const selectedUser = selected.indexOf(row.id) !== -1;

                      return (
                        <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row.id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.libelle_categorie}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2" noWrap>
                              {row.libelle_type}
                            </Typography>
                          </TableCell>

                          {/* <TableCell align="left">
                            <Label color={row.etat_categorie === 0 ? 'error' : 'success'}>
                              {row.etat_categorie === 1 ? 'Actif' : 'Inactif'}
                            </Label>
                          </TableCell> */}

                          <TableCell align="right">{moment(row.created_at).format('DD-MM-YYYY à HH:mm')}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              variant="h6"
                              paragraph
                              sx={{
                                color: 'primary.main',
                              }}
                            >
                              Aucune affectation trouvée
                            </Typography>

                            <Typography variant="body2">
                              Aucun résultat trouvé pour &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={valeurPagination}
              component="div"
              count={listeAffectations?.length ?? 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Lignes par page"
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : (
          <AffectationForm
            isEditing={isEditing}
            dataCategorie={currentItem}
            handleShowForm={handleShowForm}
            reload={chargerAffectations}
          />
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => handleShowForm({}, true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Modifier
        </MenuItem> */}
        {/* 
        <MenuItem onClick={() => handleOpenModalEtat()}>
          <Iconify icon={currentItem?.etat_categorie === 1 ? 'eva:lock-fill' : 'eva:unlock-fill'} sx={{ mr: 2 }} />
          {currentItem?.etat_categorie === 1 ? 'Désactiver' : 'Activer'}
        </MenuItem> */}

        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleOpenModalSuppression()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Supprimer
        </MenuItem>
      </Popover>

      {openModalSuppression === true && (
        <ModalSuppression
          visible={openModalSuppression}
          chargement={chargement}
          title="Suppression d'affectation"
          content={`Êtes-vous sûr de vouloir l'affectation de la catégorie "${
            currentItem && currentItem.libelle_categorie
          }" au type "${currentItem && currentItem.libelle_type}" ?`}
          confirmLabel="Oui, Supprimer l'affectation"
          handleConfirm={handleSupprimerAffectation}
          handleVisibility={handleOpenModalSuppression}
        />
      )}

      {/* {openModalEtat === true && (
        <ModalEtat
          visible={openModalEtat}
          chargement={chargement}
          title={
            currentItem && currentItem.etat_categorie === 0 ? 'Activation de catégorie' : 'Désactivation de catégorie'
          }
          content={`Êtes-vous sûr de vouloir ${
            currentItem && currentItem.etat_categorie === 0 ? 'activer' : 'désactiver'
          } la catégorie "${currentItem && currentItem.libelle_categorie}" ?`}
          confirmLabel={
            currentItem && currentItem.etat_categorie === 0
              ? 'Oui, Activer la catégorie'
              : 'Oui, désactiver la catégorie'
          }
          handleConfirm={handleModifierEtatCategorie}
          handleVisibility={handleOpenModalEtat}
        />
      )} */}
    </>
  );
};

export default withAuth(AffectationsPage);
