import { Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import useUser from '../hooks/useUser';

export default function DashboardPage() {
  const { user } = useUser();
  return (
    <>
      <Helmet>
        <title> Tableau de bord | ASACI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Tableau de bord
        </Typography>

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Articles" total={714000} icon={'ant-design:book-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Catégories" total={1723315} color="warning" icon={'ant-design:flag-filled'} />
          </Grid>
        </Grid> */}
      </Container>
    </>
  );
}
